<template>
  <div class="sample-therapist wrap space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <div class="therapist-cover">
        <img
          :src="require(`@/assets/${imgPath}`)"
          :alt="{ name }"
          class="therapist"
        />
      </div>
      <div class="guide">
        <div class="name-cover">
          <p class="name">{{ name }}</p>
          <!-- 最大全角20文字 -->
          <p class="roman">{{ roman }}</p>
          <!-- 最大半角40文字 -->
        </div>
        <div class="solved">
          <p class="title">解決できる主な悩み</p>
          <div class="block-cover">
            <div
              v-for="trouble in therapistTrouble"
              :key="trouble.id"
              class="block"
            >
              <p>{{ trouble.case }}</p>
            </div>
          </div>
          <!-- 最大9個 -->
        </div>
        <p class="description">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "SampleTherapist",
  components: {
    UiHeading,
  },
  props: {
    headingText: String,
    imgPath: String,
    name: String,
    roman: String,
    therapistTrouble: Array,
    description: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba($color: white, $alpha: 0.7);
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .therapist-cover {
    width: 50%;
    padding: 50px;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 20px;
    }
    .therapist {
      vertical-align: top;
    }
  }
  .guide {
    width: 50%;
    padding: 50px 50px 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 20px;
    }
    .name-cover {
      text-align: left;
      font-family: var(--fontfamilySub);
      .name {
        font-size: 30px;
        @media screen and (max-width: 767px) {
          font-size: 26px;
        }
      }
    }
    .solved {
      // display: flex;
      width: 100%;
      padding: 20px 0;
      .title {
        // margin-right: 10px;
        min-width: 140px !important;
        padding: 0 10px;
        height: 32px;
        background-color: var(--black);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 895px) {
          margin-right: 0;
        }
        @media screen and (min-width: 896px) and (max-width: 1024px) {
          padding: 0 8px;
        }
      }

      .block-cover {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        flex-wrap: wrap;
        .block {
          height: 32px;
          width: 30%;
          flex-basis: 30%;
          background-color: var(--sub);
          white-space: nowrap;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .block-cover::after {
        display: block;
        content: "";
        width: 30%;
      }
    }
    .description {
      text-align: left;
    }
  }
}
</style>