<template>
  <div class="sample">
    <div class="top">
      <img
        alt="セラピー向けテンプレトップ"
        :src="require(`@/assets/${headerImgPathPc}`)"
        class="pc-only"
      />
      <img
        alt="セラピー向けテンプレトップSP"
        :src="require(`@/assets/${headerImgPathSp}`)"
        class="sp-only"
      />
    </div>

    <div class="space-top-70">
      <SampleAboutUs
        class="space-top-50"
        id="about"
        :headingText="`ヒプノセラピーサロン<br class=sp-only>ABCについて`"
        :description="`ヒプノセラピーサロンABCでは、主にママ・お母様を対象としたヒプノセラピー(催眠療法)を行っています。経験豊富な女性のセラピストがお客様の心に寄り添い、さまざまな悩みやストレス、過去のトラウマからなどから開放されるように導きます。<br>ご家族に大きな影響を与えるママ・お母様が、いつも明るく幸せであることは、ご家族にとっても、大変重要なことです。心がすっきりと軽くなり、前向きに歩んでいけるようお手伝いをさせていただきますので、ぜひお任せください。<br>セラピーはサロンのみならず、オンラインでも受けていただけます。初回限定のお得なセラピーメニューもご用意しておりますので、お気軽にお試しください。`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back01)">
      <SampleFeature
        class="space-top-80"
        :headingText="'ヒプノセラピーサロン<br class=sp-only>ABCの特徴'"
        :featureBoxList="featureBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleUsers
        class="space-top-80"
        id="user"
        :headingText="`こんな人が利用しています`"
        :usersList="usersList"
      />
    </div>

    <div
      class="space-top-70 introduction-back"
      :class="{
        back01: path === '/sample01',
        back02: path === '/sample02',
        back03: path === '/sample03',
        back04: path === '/sample04',
        back05: path === '/sample05',
        back06: path === '/sample06',
        back07: path === '/sample07',
        back08: path === '/sample08',
        back09: path === '/sample09',
        back10: path === '/sample10',
        back11: path === '/sample11',
        back12: path === '/sample12',
      }"
    >
      <SampleTherapist
        class="space-top-80"
        id="introduction"
        :headingText="'セラピストの紹介'"
        :imgPath="'therapy-top-hito.jpg'"
        :name="'山田 広子'"
        :roman="'Hiroko Yamada'"
        :description="'ヒプノセラピーサロンABCのホームページをご覧いただき、ありがとうございます。私は25歳のときに出産を経験し、その後、主人の転勤や子供の病気など様々な家庭環境の変化を経験しました。このような状況下で、私身が日々悩みを抱えた生活をするようになりました。そんなときにヒプノセラピーと出会い、人生が好転しました。ヒプノセラピーで、心は軽やかになり、人生は良い方向へ動き出します。こんな体験を当サロンでは提供しています。'"
        :therapistTrouble="therapistTrouble"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleMessage
        class="space-top-80"
        :headingText="`セラピストからのメッセージ`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleMenu
        class="space-top-80"
        id="menu"
        :headingText="'セラピーメニュー'"
        :menuBoxList="menuBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleAccess
        class="space-top-80"
        id="access"
        :headingText="`ご利用方法・アクセス`"
        :howToOnline="`当サロンでは、パソコンやスマートフォン、タブレット端末を利用したオンラインセラピーを行っております。オンラインセラピーは、Zoom(ズーム)というオンラインビデオ通話サービスを利用します。Zoomのご利用料金は無料です。<br><br>オンラインセラピーの際、スマートフォンやタブレット端末をご利用の方は、あらかじめZoomアプリのインストールをお願いいたします。<br>パソコンをご利用の方は、事前にお送りするメールに記載されたURLをクリックすると、自動的にZoomがインストールされますので、あらかじめインストールしておく必要はありません。<br><br>恐れ入りますが、Zoomの設定・接続に関して、当サロンではサポートいたしかねます。<br>オンラインセラピーのご利用について不明な点がございましたら、お問い合わせよりお気軽にご質問ください。`"
        :howToMeet="`対面セラピーは、ABCヒプノセラピーサロンのセラピールーム（住所は「アクセス」に記載）、または、レンタルスペースで行います。<br><br>セラピールームは、リラックスできるアロマの香りと心地良い音楽で、セラピー効果を高める環境を整えています。お越しいただくことが可能な場合は、セラピールームでのセラピーをおすすめします。<br><br>ご予約時刻に落ち着いた状態でセッションを開始できるよう、ご予約時刻の10分前までにお越しください。`"
        :addressTableList="addressTableList"
        :aboutVisit="`名古屋市から半径20キロ以内のエリア、および、名古屋駅から電車1時間以内で駅からのアクセスが良いエリアついては、訪問によるセラピーが可能です。<br>ただし、以下の環境を整えていいただくよう、お願いいたします。<br><br>・セラピーに集中できるよう雑音がないこと<br>・途中で入室者などがなく、セラピーが中断しないこと<br>・ご自身がリラックスしてもたれることができるイスがあること<br><br>上記の条件が満たされない場合、当日、セラピーを中止させていただく場合がございます。<br>
        この場合において、セラピー代金のご返金、および、セラピーの振り替えはご対応いたしかねますので、ご了承ください。<br><br>訪問セラピーについては、ご予約時に詳細をご案内させていただきます。`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back01)">
      <SampleVoice
        class="space-top-80"
        id="voice"
        :headingText="'お客様の声'"
        :voiceBoxList="voiceBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleQa
        class="space-top-80"
        id="question"
        :qaBoxList="qaBoxList"
        :headingText="`よくある質問`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleFlow
        class="space-top-80"
        id="flow"
        :headingText="'ご予約から<br class=sp-only>セラピーまでの流れ'"
        :flowBoxList="flowBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleReserve
        class="space-top-80"
        id="contact"
        :headingText="`セラピーの<br class=sp-only>ご予約・お問い合わせ`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleInfo
        class="space-top-80"
        :headingText="'お知らせ'"
        :infoBoxList="infoBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleBlog
        class="space-top-80"
        :headingText="'ブログ'"
        :blogBoxList="blogBoxList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SampleAboutUs from "@/components/Sample/AboutUs.vue";
import SampleFeature from "@/components/Sample/Feature.vue";
import SampleUsers from "@/components/Sample/Users.vue";
import SampleTherapist from "@/components/Sample/Therapist.vue";
import SampleMessage from "@/components/Sample/Message.vue";
import SampleMenu from "@/components/Sample/Menu.vue";
import SampleAccess from "@/components/Sample/Access.vue";
import SampleVoice from "@/components/Sample/Voice.vue";
import SampleQa from "@/components/Sample/Qa.vue";
import SampleFlow from "@/components/Sample/Flow.vue";
import SampleReserve from "@/components/Sample/Reserve.vue";
import SampleInfo from "@/components/Sample/Info.vue";
import SampleBlog from "@/components/Sample/Blog.vue";
export default {
  name: "sample",
  components: {
    SampleAboutUs,
    SampleFeature,
    SampleUsers,
    SampleTherapist,
    SampleMessage,
    SampleMenu,
    SampleAccess,
    SampleVoice,
    SampleQa,
    SampleFlow,
    SampleReserve,
    SampleInfo,
    SampleBlog,
  },
  props: {
    headerImgPathPc: String,
    headerImgPathSp: String,
    path: String,
  },
  data() {
    return {
      featureBoxList: [
        {
          id: 1,
          imgPath: "therapy-top-tokutyou001.jpg",
          title: "日々の生活に忙しい<br>ママ・お母様に寄り添うセラピー",
          text: "当サロンにお越しいただくお客様の多くは、ママ・お母様となっています。セラピスト自身も2人の子供がいるからこそ、同じママ・お母様の視点で共感し、寄り添いながら、セラピーを進めていくことが可能です。ママ・お母様は、思いやりがあり、責任感が強い人こそ、すべてを自分で背負い、自分を追い込んで苦しみがちです。ぜひ自分を解放させてあげましょう。何でも話せる、そんなパートナーとなることを心がけています。",
        },
        {
          id: 2,
          imgPath: "therapy-top-tokutyou002.jpg",
          title: "セラピーは<br class=sp-only>9時～20時まで予約可能",
          text: "セラピーは、平日・土日・祝日を問わず、9時～20時まで予約可能です。ご希望の時間帯にご予約いただけます。当サロンのセラピーは、お悩みのこと、お困りのこと、現在の生活などについて詳しくお話を伺いながら、1時間ほどかけてカウンセリングを行い、その後2時間ほどかけて丁寧にセラピーを行っていきます。多くのお客様のご状況に対応できるよう、幅広い時間帯でご予約を受け付けています。",
        },
        {
          id: 3,
          imgPath: "therapy-top-tokutyou003.jpg",
          title: "お客様の満足度90%超",
          text: "当サロンでこれまでセラピーを受けられたお客様から頂いたアンケート結果では、満足度は90%を超えています。お客様の相談内容を正確に受け止めること、また、相談内容に対して的確なセラピーを適切なタイミングで提供することで、お客様のお悩みを改善することができています。強い悩みの場合でも、回数を重ね、状況を確認しながら最適なセラピーを行うことで、改善が可能です。",
        },
        {
          id: 4,
          imgPath: "therapy-top-tokutyou004.jpg",
          title: "初回体験コースあり",
          text: "当サロンのセラピーを初めて受ける方に向けて、通常料金よりもお値打ちな初回体験コースをご用意しています。まずはお気軽にお試しください。ヒプノセラピーによって深い悩みを解決するためには、セラピストとの信頼関係が重要だと考えています。そのため、初回体験では、時間をしっかりととり、対話をきちんと行うことを大切にしています。詳細はメニューの欄でご確認くださいませ。",
        },
      ],
      usersList: [
        {
          id: 1,
          users: "子育てに悩むお母様",
        },
        {
          id: 2,
          users: "出産に対する不安や<br class=pc-sp-only-users>悩みがある人",
        },
        {
          id: 3,
          users: "家族・家庭内の悩みがある人",
        },
        {
          id: 4,
          users:
            "日々の生活や家事に<br class=pc-sp-only-users>ストレスを感じている人",
        },
        {
          id: 5,
          users:
            "ネガティブな状態から<br class=pc-sp-only-users>開放されたい人",
        },
        {
          id: 6,
          users:
            "気持ちに行動が<br class=pc-sp-only-users>ついてこなくて悩んでいる人",
        },
        {
          id: 7,
          users: "消えない強い<br class=pc-sp-only-users>トラウマがある人",
        },
        {
          id: 8,
          users: "相談できる人がいない人",
        },
        {
          id: 9,
          users: "はじめてヒプノセラピーを<br class=pc-sp-only-users>受ける人",
        },
      ],
      therapistTrouble: [
        {
          id: 1,
          case: "家庭",
        },
        {
          id: 2,
          case: "子育て",
        },
        {
          id: 3,
          case: "出産",
        },
        {
          id: 4,
          case: "恋愛結婚",
        },
        {
          id: 5,
          case: "トラウマ",
        },
        {
          id: 6,
          case: "人間関係",
        },
        {
          id: 7,
          case: "不安",
        },
        {
          id: 8,
          case: "癒やし",
        },
      ],
      menuBoxList: [
        {
          id: 1,
          title: "ママ限定！<br class=sp-only>初回お試しセラピー120分",
          meeting: true,
          online: true,
          price: 11000,
          time: "120分",
          text: "初めてヒプノセラピーサロンABCをご予約される方限定のお試しセラピーです。子育て、ご家庭、ご自身のことなど、お悩みの内容について、具体的にお聞かせください。お客様のお悩みを改善するために、丁寧にカウンセリングとセラピーを行うことを心がけておりますので、場合によってはセラピーの時間を延長することがあります(追加料金はございません)。お時間に余裕があるときにご予約ください。",
        },
        {
          id: 2,
          title: "リピート限定！<br class=sp-only>ABCベーシックセラピー",
          meeting: true,
          online: true,
          price: 16500,
          time: "120分",
          text: "ヒプノセラピーサロンABCのご利用が2回目以降の方を対象にしたセラピーです。お悩みの内容によっては、継続的にセラピーを行うことで、より高い効果を見込めます。セラピーの終わりには、お悩みの改善に向けて、日々の生活の中で心がけていただくこと、実行していただくことなども設定していただきます。毎回この振り返りとセラピーをセットで行うことで、着実にお悩みの改善へとつなげていきます。",
        },
        {
          id: 3,
          title: "前世療法体験セラピー",
          meeting: true,
          online: false,
          price: 22000,
          time: "150分",
          text: "前世体験を通して、あなたの目の前の現実や思考を良い方向に導くセラピーです。当サロンは、前世療法について、5年もの実績があります。まずはリラックス状態に導き、その後、対話形式で前世の体験とセラピーを進めていきます。根深い悩みやトラウマを抱えた方は、ぜひ一度ご体験ください。※前世療法は、前世の確認を目的として行うものではなく、お悩みの改善のために、その手段として前世体験を利用するセラピーです。",
        },
      ],
      addressTableList: [
        {
          id: 1,
          th: "郵便番号",
          td: "450-0046",
        },
        {
          id: 2,
          th: "住所",
          td: "愛知県名古屋市中区栄2-5 パークハイツビル202",
        },
        {
          id: 3,
          th: "経路案内",
          td: "名古屋駅から地下鉄東山線で藤が丘方面へ2駅。栄駅で下車します。10番出口を出てすぐ左手に吉野家が入った建物があります。その建物の2階です。",
        },
      ],
      voiceBoxList: [
        {
          id: 1,
          imgPath: "therapy-top-ira002.png",
          voice:
            "長年、子供との関係で悩んでいましたが、セラピーを受けて気持ちがスッキリとして、前向きに物事を捉えられるようになりました。<br>私が変わったことで、子供も変わり、今はで良い距離感、良い関係を子供と作ることができています。",
          man: "41歳 女性",
        },
        {
          id: 2,
          imgPath: "therapy-top-ira003.png",
          voice:
            "中学2年生の子供（男子）が反抗期になり、それから家庭内の状況はボロボロでした。旦那が単身赴任をしていることもあり、頼れる人がいなくて、ずっと悩んでいました。そんなときに山田先生のセラピーを受け、少しずつ自分の中での考えが変わり、物事に向き合えるようになりました。今はどんな家庭内の問題でも、自分で向き合って答えを出していけています。",
          man: "50歳 女性",
        },
        {
          id: 3,
          imgPath: "therapy-top-ira001.png",
          voice:
            "主人との間に子供ができてから、私自身が日々の生活に心が締め付けられるようなストレスを感じるようになってしまいました。精神的にだいぶ下がっていた頃もありましたが、山田先生のヒプノセラピーを受けてから、もとの自分に戻れた気がしています。",
          man: "32歳 女性",
        },
      ],
      qaBoxList: [
        {
          id: 1,
          qestion:
            "相談内容はどんなことでも大丈夫ですか？",
          answer:
            "ママ・お母様のお悩みであれば、育児、家事、ご家族のことなど、どのような内容でも対応可能です。お悩みについて、率直に何でもご相談いただければと思います。",
        },
        {
          id: 2,
          qestion: "相談内容を秘密にしてもらえますか？",
          answer:
            "守秘義務がございますので、お客様の許可なく、お客様の個人情報やご相談内容を他者に漏らすことは一切ございません。どうぞ安心してご依頼ください。",
        },
        {
          id: 3,
          qestion: "本当に効果はありますか？",
          answer:
            "ヒプノセラピーで効果を得るには、お客様自身のセラピーに対する向き合い方、セラピストとの信頼関係が重要になります。そのため、まずは、お客様自身がセラピーに対して否定的な感情を持つことなく、前向きな姿勢で臨むことが必要になります。また、「今の自分を変えたい」「悩みを解消したい」と、お客様自身が真にそう願っている状態であることも大切です。このような状態を作ることで、効果を得やすくなります。",
        },
        {
          id: 4,
          qestion: "セラピーは継続的に受ける必要がありますか？",
          answer:
            "改善したいお悩みの大きさ・内容・求めるレベルなどによって、必要なセラピーの回数は異なります。一度のセラピーで悩みを解消できる場合もあれば、複数回のセラピーが必要になることもあります。<br>どなたも悩みはひとつではなく、多くの悩みが複雑に絡み合っていることがほとんどですので、セラピーをくり返すことで、根本的な問題を解決できる可能性が高まります。状況を見ながら、適切なセラピーをご提案させていただきます。",
        },
        {
          id: 5,
          qestion: "オンラインのセラピーと対面のセラピーのどちらがおすすめですか？",
          answer:
            "セラピーの内容はどちらも同じです。ご希望のご利用方法をお選びください。セラピールームでは、アロマの香りと心地良い音楽の中、ゆったりとした気分でセラピーを受けていただくことが可能です。",
        },
        {
          id: 6,
          qestion: "表示してある料金以外にお金はかかりませんか？",
          answer:
            "セラピーに関して、メニューに表示された料金以外は一切いただきません。当サロンではリラックス効果を高めるアロマオイルの販売もしておりますが、こちらはご希望をいただいた方にのみ販売しております。 安心してご利用ください。",
        },
        {
          id: 7,
          qestion: "セラピーの予約キャンセルについて教えてください",
          answer:
            "キャンセルやご予約日時の変更をご希望の場合は、速やかにご連絡をお願いいたします。ご予約日の3日前以降のキャンセルは、その理由に関わらず、キャンセル料としてセラピー料金の50%が発生いたしますので、ご注意くださいませ。",
        },
      ],
      flowBoxList: [
        {
          id: 1,
          no: "01",
          title: "まずはご予約のお問い合わせから",
          text: "お電話、InstagramのDM、LINE、お問い合わせフォームからご希望の方法で、ご希望のメニューとご希望のお日にち(3候補程度)をお知らせください。予約システムからご予約の場合は、予約システム上の説明を参照してください。",
          yazirushi: true,
        },
        {
          id: 2,
          no: "02",
          title: "セラピーの日時を決定する",
          text: "こちらから折り返しご連絡をし、セラピーの日程を調整します。日程が決まり次第、セラピーの当日までにご準備をお願いしたい内容などについてお伝えします。その際、ご不明な点などがございましたら、お気兼ねなく、お申し付けください。なお、予約システムでのご予約の場合は、その場で予約が確定いたします。",
          yazirushi: true,
        },
        {
          id: 3,
          no: "03",
          title: "セラピーの3日前にリマインドメッセージを確認",
          text: "セラピー予約日の3日前になりましたら、こちらからリマインドメッセージをお送りします。これ以降のキャンセルは、キャンセル料50%が発生いたしますのでご注意くださいませ。",
          yazirushi: true,
        },
        {
          id: 4,
          no: "04",
          title: "ご予約の日時にセラピーを受ける",
          text: "対面の場合は、サロンまたはご指定の場所にて、オンラインの場合はZoomにてセラピーを行います。オンラインの場合は、事前にZoomの招待メールをお送りさせていただきます。セラピーの終わりには、日々の生活で行ってほしいことについてアドバイスをしています。",
          yazirushi: false,
        },
      ],
      infoBoxList: [
        {
          id: 1,
          title: "2020年●月●日　<br class=sp-only>お知らせのタイトルを表示",
          text: "お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示",
        },
        {
          id: 2,
          title: "2020年●月●日　<br class=sp-only>お知らせのタイトルを表示",
          text: "お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示",
        },
        {
          id: 3,
          title: "2020年●月●日　<br class=sp-only>お知らせのタイトルを表示",
          text: "お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示、お知らせの本文を表示",
        },
      ],
      blogBoxList: [
        {
          id: 1,
          date: "2020年8月17日(日)：<br class=sp-only>ここにカテゴリ名を表示",
          title: "ブログ記事タイトル",
          text: "ブログ本文の冒頭の文章を表示する・・・＞ブログを見る",
        },
        {
          id: 2,
          date: "2020年8月17日(日)：<br class=sp-only>ここにカテゴリ名を表示",
          title: "ブログ記事タイトル",
          text: "ブログ本文の冒頭の文章を表示する・・・＞ブログを見る",
        },
        {
          id: 3,
          date: "2020年8月17日(日)：<br class=sp-only>ここにカテゴリ名を表示",
          title: "ブログ記事タイトル",
          text: "ブログ本文の冒頭の文章を表示する・・・＞ブログを見る",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  //   created() {
  // var VueScrollTo = require('vue-scrollto');

  // var options = {
  //     container: '#container',
  //     easing: 'ease-in',
  //     lazy: false,
  //     offset: -60,
  //     force: true,
  //     cancelable: true,
  //     onStart: function(element) {
  //       console.log(element);
  //       // scrolling started
  //     },
  //     onDone: function(element) {
  //       console.log(element);
  //       // scrolling is done
  //     },
  //     onCancel: function() {
  //       // scrolling has been interrupted
  //     },
  //     x: false,
  //     y: true
  // }

  // var cancelScroll = VueScrollTo.scrollTo("about", 500, options)
  // console.log(cancelScroll);
  //   },
  // mounted() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash !== "") {
  //       setTimeout(function(){
  //         const hash = this.$route.hash;
  //         alert('移動するよ！');
  //         this.$scrollTo(hash, 0, {});
  //       }.bind(this), 1000,);
  //     }
  //   });
  // },
  //   mounted() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash !== "") {
  //       // alert('hashある！');
  //       setTimeout(function(){
  //         const hash = this.$route.hash;
  //         console.log("koko");
  //         this.$scrollTo(hash, 0, {});
  //       }.bind(this), 1500,);
  //     }else {
  //       // alert('hashない！');
  //     }
  //   });
  // },
  created() {
    this.$nextTick(() => {
      this.OriginalVueScrollTo(this.$route.query.position);
    });
  },
  methods: {
    OriginalVueScrollTo(id) {
      //
      let element = "#" + id;
      let VueScrollTo = require("vue-scrollto");

      //
      let duration = 0; // 実行までの時間（ms）

      //
      let option = {
        offset: -90, // ゴール地点からどれだけズレた位置に飛ぶか
      };

      // scroll実行
      VueScrollTo.scrollTo(element, duration, option);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Noto+Serif+JP:wght@400;700&display=swap");
.sample {
  font-family: var(--fontfamilyMain);
}
.top {
  img {
    vertical-align: top;
    width: 100vw;
  }
}
.back01 {
  background-image: url(~@/assets/therapy-top-hito-back_01.jpg);
}
.back02 {
  background-image: url(~@/assets/therapy-top-hito-back_02.webp);
}
.back03 {
  background-image: url(~@/assets/therapy-top-hito-back_03.webp);
}
.back04 {
  background-image: url(~@/assets/therapy-top-hito-back_04.webp);
}
.back05 {
  background-image: url(~@/assets/therapy-top-hito-back_05.webp);
}
.back06 {
  background-image: url(~@/assets/therapy-top-hito-back_06.webp);
}
.back07 {
  background-image: url(~@/assets/therapy-top-hito-back_07.webp);
}
.back08 {
  background-image: url(~@/assets/therapy-top-hito-back_08.webp);
}
.back09 {
  background-image: url(~@/assets/therapy-top-hito-back_09.webp);
}
.back10 {
  background-image: url(~@/assets/therapy-top-hito-back_10.webp);
}
.back11 {
  background-image: url(~@/assets/therapy-top-hito-back_11.webp);
}
.back12 {
  background-image: url(~@/assets/therapy-top-hito-back_12.webp);
}
.introduction-back {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 767px) {
    background-position: 70% 50%;
  }
}
.space-top-50 {
  margin-top: 50px;
}
</style>