<template>
  <div class="sample-feature-feature">
    <div class="feature-box" style="background-color: white">
      <img
        :src="require(`@/assets/${imgPath}`)"
        :alt="{ title }"
        class="photo"
      />
      <div class="title-cover">
        <p v-html="title" class="title"></p>
      </div>
      <div class="detail">
        <p v-html="text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "SampleFeatureFeature",
  components: {
    // UiHeading,
  },
  props: {
    imgPath: String,
    title: String,
    text: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.feature-box {
  padding: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding: 25px 25px 50px;
  }
  .title-cover {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100px;
    p {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .title {
      font-family: var(--fontfamilySub);
    }
  }
  .detail {
    text-align: left;
    height: 255px;
    @media screen and (min-width: 768px) and (max-width: 848px) {
      height: 330px;
    }
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
}
</style>