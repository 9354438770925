<template>
  <div class="sample-voice space-btm-200p wrap">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <SampleVoiceVoiceBox
        v-for="voiceBoxListData in voiceBoxList"
        :key="voiceBoxListData.id"
        :imgPath="voiceBoxListData.imgPath"
        :voice="voiceBoxListData.voice"
        :man="voiceBoxListData.man"
        class="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleVoiceVoiceBox from "@/components/Sample/Voice/VoiceBox.vue";

export default {
  name: "SampleMessage",
  components: {
    UiHeading,
    SampleVoiceVoiceBox
  },
  props: {
    headingText: String,
    voiceBoxList:Array,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    // font-size: 28px;
  }
  .item{
    &:nth-child(2n){
      display: flex;
      justify-content: flex-end;
      }
    &:nth-child(2n+1){
      display: flex;
      justify-content: flex-start;
    }
  }
}
</style>