<template>
  <div class="sample-flow wrap space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <SampleInfoInfoBox
        v-for="infoBoxListData in infoBoxList"
        :key="infoBoxListData.id"
        :title="infoBoxListData.title"
        :text="infoBoxListData.text"
        class="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleInfoInfoBox from "@/components/Sample/Info/InfoBox.vue";

export default {
  name: "SampleQa",
  components: {
    UiHeading,
    SampleInfoInfoBox,
  },
  props: {
    infoBoxList: Array,
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: white;
  padding: 100px 0;
}
</style>