<template>
  <div class="sample-users">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <div
        class="item"
        v-for="usersListData in usersList"
        :key="usersListData.id"
      >
        <p v-html="usersListData.users"></p>
        <!-- usersListData.users 最大20文字 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "SampleFeature",
  components: {
    UiHeading,
  },
  props: {
    usersList: Array,
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 180px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 100px;
  }
  .item {
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 150px;
    padding: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      font-size: 22px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    &:nth-child(2n + 1) {
      border: 10px var(--base) solid;
    }
    &:nth-child(2n) {
      border: 10px var(--sub) solid;
    }
  }
}

.cover::after {
  content: "";
  display: block;
  width: 32%;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
</style>