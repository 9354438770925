<template>
  <div class="sample-about-us">
      <UiHeading :h="'1'" :type="'E2'" :headingText="headingText" />
      <div class="description space-btm-140p wrap2">
        <p v-html="description"></p></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "SampleAboutUs",
  components: {
    UiHeading,
  },
  props: {
    description:String,
    headingText:String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.description{

  p{
    text-align: left;
  }
}
.wrap2 {
  width: 100vw; 
  max-width: 920px; 
  margin: 0 auto;
  line-height: 2em;

  @media screen and (max-width: 920px) {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.6em;
  }
}
.space-btm-140p {
  padding-bottom: 140px;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }
}
</style>