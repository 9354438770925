<template>
  <div id="app">
    <Header :path="path" :style="designData['styles']" />
    <router-view
      class="body-top"
      :path="path"
      :style="designData['styles']"
      :headerImgPathPc="designData['headerImgPathPc']"
      :headerImgPathSp="designData['headerImgPathSp']"
    />
    <ToTopButton :path="path" :style="designData['styles']" />
    <Footer :style="designData['styles']" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
    designData() {
      let fontFamilyMincho =
        "'游明朝', 'Yu Mincho', 'YuMincho', 'Hiragino Mincho Pro', Noto Serif JP, serif";

      let designData = {
        styles: {},
        headerImgPathPc: {},
        headerImgPathSp: {},
      };
      if (this.path === "/sample01") {
        designData["styles"] = {
          "--base": "#f7c3bf",
          "--sub": "#efebe0",
          "--accent": "#8ac8cf",
          "--back01": "#fdf2f1",
          "--back02": "#faf8f4",
          "--rgb-sub": "239, 235, 224",
          "--rgb-accent": "138, 200, 207",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample01.webp";
        designData["headerImgPathSp"] = "header_sample_sp01.webp";
      } else if (this.path === "/sample02") {
        designData["styles"] = {
          "--base": "#bde1da",
          "--sub": "#feeedc",
          "--accent": "#f5b5a7",
          "--back01": "#f1f9f8",
          "--back02": "#fff9f3",
          "--rgb-sub": "254, 238, 220",
          "--rgb-accent": "245, 181, 167",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample02.webp";
        designData["headerImgPathSp"] = "header_sample_sp02.webp";
      } else if (this.path === "/sample03") {
        designData["styles"] = {
          "--base": "#f9cfd1",
          "--sub": "#fff8ee",
          "--accent": "#f39f89",
          "--back01": "#fef4f4",
          "--back02": "#fff8ee",
          "--rgb-sub": "255, 248, 238",
          "--rgb-accent": "243, 159, 137",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample03.webp";
        designData["headerImgPathSp"] = "header_sample_sp03.webp";
      } else if (this.path === "/sample04") {
        designData["styles"] = {
          "--base": "#dde67b",
          "--sub": "#fce9ed",
          "--accent": "#f3a7ac",
          "--back01": "#f8fae6",
          "--back02": "#fef4f6",
          "--rgb-sub": "252, 233, 237",
          "--rgb-accent": "243, 167, 172",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample04.webp";
        designData["headerImgPathSp"] = "header_sample_sp04.webp";
      } else if (this.path === "/sample05") {
        designData["styles"] = {
          "--base": "#e6afcf",
          "--sub": "#f8f5b5",
          "--accent": "#8ab0bc",
          "--back01": "#fbf3f8",
          "--back02": "#fdfce9",
          "--rgb-sub": "248, 245, 181",
          "--rgb-accent": "138, 176, 188",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample05.webp";
        designData["headerImgPathSp"] = "header_sample_sp05.webp";
      } else if (this.path === "/sample06") {
        designData["styles"] = {
          "--base": "#bcd537",
          "--sub": "#d9eaec",
          "--accent": "#74a64c",
          "--back01": "#f7faea",
          "--back02": "#f2f7f8",
          "--rgb-sub": "217, 234, 236",
          "--rgb-accent": "116, 166, 76",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample06.webp";
        designData["headerImgPathSp"] = "header_sample_sp06.webp";
      } else if (this.path === "/sample07") {
        designData["styles"] = {
          "--base": "#faca7b",
          "--sub": "#e0f1f4",
          "--accent": "#f096a0",
          "--back01": "#fef7ec",
          "--back02": "#f3fafb",
          "--rgb-sub": "224, 241, 244",
          "--rgb-accent": "240, 150, 160",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample07.webp";
        designData["headerImgPathSp"] = "header_sample_sp07.webp";
      } else if (this.path === "/sample08") {
        designData["styles"] = {
          "--base": "#a2997f",
          "--sub": "#cfdedf",
          "--accent": "#6e6457",
          "--back01": "#f8f7f6",
          "--back02": "#f5f9f9",
          "--rgb-sub": "207, 222, 223",
          "--rgb-accent": "110, 100, 87",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample08.webp";
        designData["headerImgPathSp"] = "header_sample_sp08.webp";
      } else if (this.path === "/sample09") {
        designData["styles"] = {
          "--base": "#eac5b6",
          "--sub": "#ece4e1",
          "--accent": "#c1a37d",
          "--back01": "#fbf5f3",
          "--back02": "#f7f4f3",
          "--rgb-sub": "236, 228, 225",
          "--rgb-accent": "193, 163, 125",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample09.webp";
        designData["headerImgPathSp"] = "header_sample_sp09.webp";
      } else if (this.path === "/sample10") {
        designData["styles"] = {
          "--base": "#9ec3bc",
          "--sub": "#eadfca",
          "--accent": "#aa998a",
          "--back01": "#f5f9f8",
          "--back02": "#fcfaf6",
          "--rgb-sub": "234, 223, 202",
          "--rgb-accent": "170, 153, 138",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample10.webp";
        designData["headerImgPathSp"] = "header_sample_sp10.webp";
      } else if (this.path === "/sample11") {
        designData["styles"] = {
          "--base": "#cb7f69",
          "--sub": "#f8ecba",
          "--accent": "#d7b63d",
          "--back01": "#fbf5f3",
          "--back02": "#fefbf0",
          "--rgb-sub": "248, 236, 186",
          "--rgb-accent": "215, 182, 61",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample11.webp";
        designData["headerImgPathSp"] = "header_sample_sp11.webp";
      } else if (this.path === "/sample12") {
        designData["styles"] = {
          "--base": "#bdd8dd",
          "--sub": "#e6c5cf",
          "--accent": "#af9dc0",
          "--back01": "#f7fafb",
          "--back02": "#faf4f6",
          "--rgb-sub": "230, 197, 207",
          "--rgb-accent": "175, 157, 192",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample12.webp";
        designData["headerImgPathSp"] = "header_sample_sp12.webp";
      } else {
        designData["styles"] = {
          "--base": "#f7c3bf",
          "--sub": "#efebe0",
          "--accent": "#8ac8cf",
          "--back01": "#fdf2f1",
          "--back02": "#faf8f4",
          "--rgb-sub": "239, 235, 224",
          "--rgb-accent": "138, 200, 207",
          "--black": "#4e4a49",
          "--footer": "#757575",
        };
        designData["headerImgPathPc"] = "header_sample01.webp";
        designData["headerImgPathSp"] = "header_sample_sp01.webp";
      }

      if (this.$route.query.fontfamily === "g") {
        designData["styles"]["--fontfamilyMain"] = "'游ゴシック Medium','Yu Gothic Medium','游ゴシック体','YuGothic','Hiragino Sans', 'Hiragino Kaku Gothic ProN','sans-serif'";
        designData["styles"]["--fontfamilySub"] = "'游ゴシック Medium','Yu Gothic Medium','游ゴシック体','YuGothic','Hiragino Sans', 'Hiragino Kaku Gothic ProN','sans-serif'";
      } else if (this.$route.query.fontfamily === "mg") {
        designData["styles"]["--fontfamilyMain"] = "'游ゴシック Medium','Yu Gothic Medium','游ゴシック体','YuGothic','Hiragino Sans', 'Hiragino Kaku Gothic ProN','sans-serif'";
        designData["styles"]["--fontfamilySub"] = fontFamilyMincho;
      } else {
        designData["styles"]["--fontfamilyMain"] = fontFamilyMincho;
        designData["styles"]["--fontfamilySub"] = fontFamilyMincho;
      }
      return designData;
    },
  },
  created() {
    // alert(this.$route.query.fontfamily);
  },
};
</script>

<style lang="scss">
#app {
  // font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro",
  //   "Noto Serif JP", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 16px;
  color: #4e4a49;
  line-height: 2.4em;
}

// :root {
//   --base: #f7c3bf;
//   --accent: #8ac8cf;
//   --rgb-accent: 138, 200, 207;
//   --sub: #efebe0;
//   --rgb-sub: 239, 235, 224;
//   --back01: #fdf2f1;
//   --back02: #faf8f4;
//   --black: #4e4a49;
//   --footer: #757575;
// }

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.6em;
  }
}
.sp-only {
  display: none;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.tb-only {
  display: none;
  @media screen and (max-width: 809px) {
    display: block !important;
  }
}

//users用
.pc-sp-only-users {
  @media screen and (min-width: 768px) and (max-width: 1056px) {
    display: none;
  }
}
.tb-only-users {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1056px) {
    display: block !important;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}

h1,
h2 {
  font-size: 32px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}
h3 {
  font-size: 30px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
}
h4 {
  font-size: 24px;
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
}
h5 {
  font-size: 20px;
}

//共通スペース
.space-top-70 {
  padding-top: 70px;
  @media screen and (max-width: 767px) {
    padding-top: 10px;
  }
}

.space-btm-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-btm-200p {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.space-top-80 {
  margin-top: 80px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-top-130 {
  margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-top-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
</style>
