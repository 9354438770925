<template>
  <div class="sample-feature wrap">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">

      <SampleFeatureFeatureBox
        v-for="featureBoxListData in featureBoxList"
        :key="featureBoxListData.id"
        :imgPath="featureBoxListData.imgPath"
        :title="featureBoxListData.title"
        :text="featureBoxListData.text"
        class="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleFeatureFeatureBox from "@/components/Sample/Feature/FeatureBox.vue";

export default {
  name: "SampleFeature",
  components: {
    UiHeading,
    SampleFeatureFeatureBox,
  },
  props: {
    featureBoxList: Array,
    headingText:String,
    
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 50px;
  }
.item{
  width: 48%;
  @media screen and (max-width: 767px){
    width: 100%;
  }
}
}
</style>