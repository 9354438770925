<template>
  <div class="sample-message space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <img alt="動画サンプル" src="@/assets/therapy-top-video-sample.png"/>
      <!-- 本番はyoutubeのパスに変更する -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "SampleMessage",
  components: {
    UiHeading,
  },
  props: {
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;

}
</style>