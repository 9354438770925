<template>
  <div class="sample-flow space-btm-200p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <SampleBlogBlogBox
        v-for="blogBoxListData in blogBoxList"
        :key="blogBoxListData.id"
        :date="blogBoxListData.date"
        :title="blogBoxListData.title"
        :text="blogBoxListData.text"
        class="item"
      />
      <a href="http://" target="_blank" rel="noopener noreferrer"><div class="bt">もっと見る</div></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleBlogBlogBox from "@/components/Sample/Blog/BlogBox.vue";

export default {
  name: "SampleQa",
  components: {
    UiHeading,
    SampleBlogBlogBox,
  },
  props: {
    blogBoxList: Array,
    headingText: String,
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: white;
  padding: 30px 0;
  a{
    text-decoration: none;
    .bt{
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
      width: 250px;
      padding: 20px 0;
      background-color: var(--accent);
      color: white;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    &:hover{
      opacity: 0.7;
    }
  }
}
</style>