import { render, staticRenderFns } from "./Therapist.vue?vue&type=template&id=29c3efff&scoped=true&"
import script from "./Therapist.vue?vue&type=script&lang=js&"
export * from "./Therapist.vue?vue&type=script&lang=js&"
import style0 from "./Therapist.vue?vue&type=style&index=0&id=29c3efff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c3efff",
  null
  
)

export default component.exports