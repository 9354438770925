<template>
<div class="home">
<p>Home🐤</p>
<div><router-link to="/sample01">sample01🧚</router-link>


</div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'sample01',
  components: {

  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.home{
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a{
    text-decoration: none;
    color: var(--black);
    &:hover{
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
