var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-button",attrs:{"ontouchstart":""}},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: -70,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: -70,\n    }"}],staticClass:"to-top-button",class:{ 'over-baseline': _vm.scrollY >= 500 }},[_c('div',{staticClass:"top"},[_c('svg',{staticClass:"bt",attrs:{"id":"レイヤー_1","data-name":"レイヤー 1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 205 205","width":"70px","height":"70px"}},[_c('defs'),_c('rect',{staticClass:"cls-1",attrs:{"width":"205","height":"205"}}),_c('circle',{staticClass:"cls-2",attrs:{"cx":"102.5","cy":"102.5","r":"100"}}),_c('polyline',{staticClass:"cls-3",attrs:{"points":"67.24 125.02 102.5 81.74 137.76 125.02"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }