<template>
  <div class="sample-menu space-btm-200p wrap">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <SampleMenuMenuBox
        v-for="menuBoxListData in menuBoxList"
        :key="menuBoxListData.id"
        :title="menuBoxListData.title"
        :text="menuBoxListData.text"
        :meeting="menuBoxListData.meeting"
        :online="menuBoxListData.online"
        :price="menuBoxListData.price"
        :time="menuBoxListData.time"
        class="item"
      />
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleMenuMenuBox from "@/components/Sample/Menu/MenuBox.vue";

export default {
  name: "SampleMenu",
  components: {
    UiHeading,
    SampleMenuMenuBox,
  },
  props: {
    headingText: String,
    menuBoxList:Array
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;

}
</style>