<template>
  <div class="sample-menu-menu">
    <div class="menu-box-cover" style="background-color: white">
      <div class="menu-box">
        <div class="title-cover">
          <div style="text-align: left;">
          <p v-html="title"  class="title"></p></div>
          <!-- 最大30文字 -->
          <div class="format-cover">
          <p v-show="meeting === true" class="format">対面</p>
          <p v-show="online === true" class="format">オンライン</p>
        </div>
        </div>
        <div class="price_time">
          <p>{{ price | localeNum }}円<span>（税込）</span>/{{ time }}</p>
        </div>
        <div class="detail">
          <p v-html="text"></p>
          <!-- 最大200文字 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "Samplemenu",
  components: {
    // UiHeading,
  },
  props: {
    title: String,
    text: String,
    meeting:Boolean,
    online:Boolean,
    price:Number,
    time:String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.menu-box-cover{
  background-color: white;
  margin-bottom: 30px;

  .menu-box{
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
  }
  .title-cover{
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .title{
      display: inline;
      background: linear-gradient(transparent 60%, var(--base) 60%);
      font-size: 26px;
      text-align: left;
      font-family: var(--fontfamilySub);
      @media screen and (max-width: 767px) {
        font-size: 24px;
        width: 100%;

    }}
    .format-cover{
      display: flex;

      .format{
        height: 32px;
      white-space: nowrap;
      background-color: var(--sub);
      margin: 0 5px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        margin: 10px 10px 0 0 ;
      }

      }
    }
  }
    .price_time{
      text-align: left;
      font-size: 24px;
      font-weight: bold;
      padding: 20px 0;
      font-family: var(--fontfamilySub);
        @media screen and (max-width: 767px) {
          font-size: 20px;
          padding-top: 10px;
        }
      span{
        font-size: 16px;
      }
    }
    .detail{
      text-align: left;
    }
}
</style>