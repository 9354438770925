<template>
  <div class="sample-info-infobox">
        <div class="cover">
          <h5 v-html="title" class="title"></h5>
          <p v-html="text" class="text"></p>
      </div>
    </div>


</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sampleInfoInfobox",
  components: {
  },
  props: {
    title: String,
    text: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover{
width:80%;
text-align: left;
margin-bottom: 50px;
margin-right: auto;
margin-left: auto;

.title{
  font-family: var(--fontfamilySub);
}
}
</style>