import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto/vue-scrollto.js'
import VueHead from 'vue-head'
import VueCarousel from 'vue-carousel'

Vue.use(VueHead)

Vue.use(VueScrollTo, {
  offset: -100
})

Vue.use(VueCarousel);

//Vue.filter
//¥のコンマ
Vue.filter('localeNum', function(val) {
  let int = parseInt(val);
  return int.toLocaleString()
})

//グローバル登録
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
