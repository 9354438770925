import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'
import Sample from '../views/Sample.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/sample01',
    meta: {
      title: 'セラピー向けテンプレHP',
      description: 'therapy01'
    }
  },

  {
    path: '/sample01',
    name: 'sample01',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample01/',
    redirect: '/sample01'
  },

  {
    path: '/sample02',
    name: 'sample02',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample03',
    name: 'sample03',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample04',
    name: 'sample04',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample05',
    name: 'sample05',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample06',
    name: 'sample06',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample07',
    name: 'sample07',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample08',
    name: 'sample08',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample09',
    name: 'sample09',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample10',
    name: 'sample10',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample11',
    name: 'sample11',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },
  {
    path: '/sample12',
    name: 'sample12',
    component: Sample,
    meta: {
      title: '子育てママ専門のヒプノセラピーサロンABC',
      description: '岐阜県岐阜市にある子育てママ専門のヒプノセラピーサロンABCでは、子育てや家庭の悩みを抱えるママをサポーしています。二児の子供を持つセラピストがあなたのお悩みに寄り添い、一緒にその解消・解消へと導いています。Zoomによるセラピーも行っていますので、全国どこのエリアの方でもセラピーを受けることができます。'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to) {
//     console.log(to.hash);
//     if (to.hash) {
//       return {
//         el: to.hash
//       };
//     } else {
//       return {
//         top: 0
//       };
//     }

//   }
// });

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");
  if (to.path.slice(-1) === "/") {
    next(to.path.slice(0, -1));
  } else {
    next();
  }
});

export default router
