<template>
  <div class="home-reason">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover-top">
      <UiHeading
        :h="'4'"
        :type="'B'"
        :headingText="'予約システムからの<br class=sp-only>ご予約・お問い合わせ'"
      />
      <a
        href="https://beauty.hotpepper.jp/CSP/kr/reserve/?storeId=H000387925&ch=1&vos=cpshbkprocap0140516003"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p class="reserve-button">予約システムへ</p></a
      >
    </div>

    <div class="cover">
      <UiHeading
        :h="'4'"
        :type="'B'"
        :headingText="'お電話での<br class=sp-only>ご予約・お問い合わせ'"
      />
      <a href="tel:0905555555"><p class="tel">090-5555-5555</p></a>
      <div class="hour">(受付時間：9時～20時) </div>
    </div>

    <div class="contact-cover">
      <UiHeading
        :h="'4'"
        :type="'B'"
        :headingText="'InstagramのDMからの<br class=sp-only>ご予約・お問い合わせ'"
      />
      <a href="" target="_blank" rel="noopener noreferrer">
        <img alt="Instagram" src="@/assets/insta-logo.jpg" class="insta" />
      </a>
      <p class="note">ID：hypno-abc</p>
      <span>Instagramのアイコンから、DMについてお問い合わせくださいませ</span>
    </div>

    <div class="cover contact">
      <UiHeading
        :h="'4'"
        :type="'B'"
        :headingText="'LINEでの<br class=sp-only>ご予約・お問い合わせ'"
      />
      <a href="" target="_blank" rel="noopener noreferrer">
        <img alt="Instagram" src="@/assets/line_button.png" class="line" />
      </a>
      <p class="note">友だち追加のアイコンから、友達を追加して、メッセージにてお問い合わせくださいませ</p>
    </div>

    <div class="cover">
      <UiHeading
        :h="'4'"
        :type="'B'"
        :headingText="'メールでの<br class=sp-only>ご予約・お問い合わせ'"
      />
    </div>
    <SampleReserveContactForm />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleReserveContactForm from "@/components/Sample/Reserve/ContactForm.vue";
export default {
  name: "HomeReason",
  components: {
    UiHeading,
    SampleReserveContactForm,
  },
  props: {
    headingText: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover-top,.cover,.contact-cover {
  text-align: center;
  margin-top: 100px;
  p,
  img {
    margin-top: 50px;
  }
  a {
    text-decoration: none !important;
  }
  p {
    // font-weight: bold;
    font-size: 20px;
  }
  .reserve-button {
    background-color: var(--accent);
    width: 300px;
    color: white;
    padding: 20px 0;
    margin-right: auto;
    margin-left: auto;
    @media screen and (max-width: 767px){
      width: 250px;
      font-size: 18px;
    }
  }
  .tel {
    font-size: 50px;
    font-weight: bold;
    color: var(--base);
    letter-spacing: 0.2em;
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
      font-size: 34px;
    }
  }
  .hour {
    font-size: 22px;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .insta {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100px;
      margin-top: 40px;
    }
  }
  .line {
    width: 250px;
    @media screen and (max-width: 767px){
      width: 200px;
    }
  }
  .note{
    margin-top: 20px;
    font-size: 16px;
    // font-weight: mid;
  }

  .insta,
  .reserve-button,.line {
    display: inline-block;
    transition: 0.3s;
    -webkit-transform: scale(1);
    transform: scale(1);
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}
@media (min-width: 751px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
.contact-cover{
  @media screen and (max-width: 767px){
    margin-top: 60px;
  }
}
.cover-top{
  @media screen and (max-width: 767px) {
    margin-top: 70px;
  }
}
</style>