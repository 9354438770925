<template>
  <div class="heading">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeB2: type === 'B2',
        typeB3: type === 'B3',
        typeB4: type === 'B4',
        typeB5: type === 'B5',
        typeB6: type === 'B6',
        typeB7: type === 'B7',
        typeC: type === 'C',
        typeD: type === 'D',
        typeD2: type === 'D2',
        typeE: type === 'E',
        typeE2: type === 'E2',
        typeE3: type === 'E3',
        typeE4: type === 'E4',
      }"
    >
      <h1 v-if="h === '1'" v-html="headingText"></h1>
      <h2 v-else-if="h === '2'" v-html="headingText"></h2>
      <h3 v-else-if="h === '3'" v-html="headingText"></h3>
      <h4 v-else-if="h === '4'" v-html="headingText"></h4>
      <h5 v-else-if="h === '5'" v-html="headingText"></h5>

        <div v-if="type === 'E' || 'E2'" class="black-line"></div>
      <div v-if="subTitle !== ''" class="sub-title">
        <p>
          {{ subTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "heading",
  components: {},
  props: {
    headingText: String,
    h: String,
    type: String,
    subTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.heading{
  font-family: var(--fontfamilySub)!important;
}

//paddingバーの下の幅は設定してね♡
// h1,
// h2 {
//   font-size: 32px;
//   @media screen and (max-width: 767px) {
//     font-size: 28px;
//     padding: 0 10px;
//     line-height: 1.5em;
//   }
// }
// h3 {
//   font-size: 22px;
//   @media screen and (max-width: 767px) {
//     font-size: 20px;
//   }
// }
.typeA {
  // color: var(--main);

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
}
.typeB,
.typeB3,
.typeB4,
.typeB5,
.typeB6,
.typeB7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--black);
    line-height: 1.4em;
  }
}
.typeB {
  h2 {
    @media screen and (max-width: 767px) {
      font-size: 27px;
    }
  }
  .sub-title {
    color: var(--sub);
  }
}
.typeB,
.typeB3 {
  //背景メインカラー
  background-color: var(--back01);
}
.typeB2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--main);
  }
}
.typeB2,
.typeB4 {
  //背景サブカラー
  background-color: var(--sub);
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  h3 {
    color: var(--main);
  }
}
.typeB6 {
  //背景サブカラー
  background-color: #5f5754;
  h3 {
    color: white;
    font-weight: bold;
  }
}
.typeB3,
.typeB4 {
  //SP：3行用
  height: 100px;
  @media screen and (max-width: 767px) {
    height: 100%;
    h2 {
      font-size: 21px;
      padding: 15px 0;
    }
  }
}
.typeB5 {
  //枠メインカラー
  border: 2px solid var(--main);
  height: 80px;
  @media screen and (max-width: 767px) {
    height: 60px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--main);
  }
  h2 {
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
}
.typeB7 {
  //背景サブカラー
  background-color: #5f5754;
  height: 100px;
  @media screen and (max-width: 767px) {
    height: 100%;
    h3 {
      color: white;
      font-weight: bold;
      font-size: 26px;
      text-align: center;
    }
  }
}
.typeC {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    border-bottom: var(--main) solid 1px;
    padding-bottom: 10px;
    font-weight: 500;
    padding-left: 10px;
  }
  .sub-title {
    text-align: left;
    padding-left: 10px;
  }
}
.typeD,
.typeD2 {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }
}
.typeD {
  // color: var(--sub);
}
.typeD2 {
  color: var(--main);
}
.typeE {
  text-align: center;
  .black-line {
    border-bottom: solid 2px var(--black);
    margin: 20px auto;
    width: 78px;
  }
}
.sub-title {
  color: var(--main);
  text-align: center;
  padding-top: 5px;
  font-size: 18px;
}
.typeE2 {
  h1,h2{
    line-height: 1.6em;
  }
  text-align: center;
  .black-line {
    border-bottom: solid 1px var(--black);
    margin: 30px auto 70px;//lineの間隔はここで指定
    width: 78px;
    @media screen and (max-width: 767px) {
      margin-bottom: 50px;
    }
  }
  .sub-title {
    color: var(--sub2);
  }
}
.typeE3,
.typeE4 {
  padding-bottom: 50px;
  @media screen and(max-width: 767px) {
    padding-bottom: 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding-bottom: 10px;
    color: var(--main);
    font-size: 36px;
    font-weight: 400;
    @media screen and (max-width: 767px) {
      font-size: 28px;
      padding-bottom: 0;
    }
  }
  .black-line {
    border-bottom: solid 1px var(--main);
    margin: 20px auto;
    width: 78px;
  }
  .sub-title {
    color: var(--sub2);
  }
}
.typeE4 {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding-bottom: 10px;
    color: var(--sub);
    font-size: 36px;
    font-weight: 400;
    @media screen and (max-width: 767px) {
      font-size: 28px;
      padding-bottom: 0;
    }
  }
  .black-line {
    border-bottom: solid 1px white;
    margin: 20px auto;
    width: 78px;
  }
}
p {
  margin: 0;
}
h2 {
  margin: 0;
}
</style>
